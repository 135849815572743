<template>
  <div>
    <br />
    <div class="row justify-content-end">
      <div v-if="customer.reference" class="col-auto">
        <button @click="referesh()" class="btn btn-secondary">
          <i class="bi bi-plus-circle"></i> Actualiser
        </button>
      </div>

      <div class="col-auto">
        <button @click="createProfessionalReservation" class="btn btn-primary">
          <i class="fa fa-plus"></i> Ajouter Réservation Professionnelle
        </button>
      </div>

      <div v-if="customer.reference" class="col-auto">
        <button @click="createReservation" class="btn btn-secondary">
          <i class="bi bi-plus-circle"></i> Ajouter Réservation
        </button>
      </div>

      <div v-if="customer.reference" class="col-auto">
        <button @click="createSubscription" class="btn btn-secondary">
          <i class="bi bi-plus-circle"></i> Ajouter Abonnement
        </button>
      </div>

      <div v-if="customer.reference" class="col-auto">
        <button @click="createPayment" class="btn btn-secondary">
          <i class="bi bi-plus-circle"></i> Ajouter Paiement
        </button>
      </div>
    </div>

    <br />

    <!-- Informations du Client -->
    <div class="row">
      <div class="col-xl-3 col-12 my-3" v-for="(value, label) in customerInfo" :key="label">
        <label>{{ label }}</label>
        <div>{{ value }}</div>
      </div>
    </div>

    <!-- Tableau des Abonnements -->
    <div>
      <h3>Abonnements</h3>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Pack</th>
          <th>Heures</th>
          <th>Solde</th>
          <th>Prix</th>
          <th>Total</th>
          <th>Payé</th>
          <th>Montant Payé</th>
          <th>Date de Création</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="subscription in customer.subscriptions" :key="subscription.id">
          <td>{{ subscription.pricingFullName }}</td>
          <td>{{ subscription.hours }}</td>
          <td>{{ subscription.soldHoursRest }}</td>
          <td>{{ subscription.price }}</td>
          <td>{{ subscription.hours * subscription.price }}</td>
          <td>
            <i
              :class="subscription.paid ? 'bi bi-check2-all text-success' : 'bi bi-exclamation-triangle-fill text-danger'"></i>
          </td>
          <td>{{ subscription.totalPaid }}</td>
          <td>{{ subscription.created_at | dateTime }}</td>
          <td>
            <button
              @click="$router.push({ name: 'subscriptions-details', params: { reference: subscription.reference } })"
              class="btn btn-info">Afficher</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Tableau des Paiements -->
    <div>
      <h3>Paiements</h3>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th>Référence</th>
          <th>Date</th>
          <th>Montant Facturé</th>
          <th>Mode</th>
          <th>Montant Payé</th>
          <th>Montant Impayé</th>
          <th>Remarque</th>
          <th>Créé par</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(payment, index) in customer.payments" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ payment.reference }}</td>
          <td>{{ payment.date }}</td>
          <td>{{ payment.sale ? payment.sale.totalPriceTTC : '' }}</td>
          <td>{{ payment.paymentMethod }}</td>
          <td>{{ payment.amount | toFixedWithSpace }}</td>
          <td>{{ payment.remainingAmount | toFixedWithSpace }}</td>
          <td>{{ payment.remark }}</td>
          <td>{{ payment.user ? payment.user.name : '' }}</td>
          <td>
            <button @click="$router.push({ name: 'payments-details', params: { reference: payment.reference } })"
              class="btn btn-info">
              <i class="bi bi-info-square"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Tableau des Matches -->
    <div>
      <h3>Matches</h3>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Terrain</th>
          <th>Date</th>
          <th>Équipe A</th>
          <th>Équipe B</th>
          <th>Buts A</th>
          <th>Buts B</th>
          <th>Heure de Début</th>
          <th>Heure de Fin</th>
          <th>Statut</th>
          <th>Remarque</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="matche in customer.matches" :key="matche.id">
          <td>{{ matche.terrainFullName }}</td>
          <td>{{ matche.date }}</td>
          <td>{{ matche.fullNameTeamA }}</td>
          <td>{{ matche.fullNameTeamB }}</td>
          <td>{{ matche.goalsTeamA }}</td>
          <td>{{ matche.goalsTeamB }}</td>
          <td>{{ matche.startTime }}</td>
          <td>{{ matche.endTime }}</td>
          <td>
            <button @click="toggleMatchStatus(matche)" :class="matche.is_active ? 'btn btn-success' : 'btn btn-danger'">
              {{ matche.is_active ? 'En jouant' : 'En attente' }}
            </button>
          </td>
          <td>{{ matche.remark }}</td>
          <td>
            <button @click="$router.push({ name: 'matches-edit', params: { reference: matche.reference } })"
              class="btn btn-info">Editer</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Tableau des Réservations -->
    <div>
      <h3>Réservations</h3>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Référence</th>
          <th>Date</th>
          <th>Prix</th>
          <th>Heure début</th>
          <th>Heure fin</th>
          <th>Payée</th>
          <th>Montant payé</th>
          <th>Abonnement</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <!-- Loop over reservations (assuming it's part of 'customer.bookings') -->
        <tr v-for="booking in customer.bookings" :key="booking.id">
          <td>{{ booking.reference }}</td>
          <td>{{ booking.date }}</td>
          <td>{{ booking.price }} DH</td>
          <td>{{ booking.startTime }}</td>
          <td>{{ booking.endTime }}</td>
          <td>
            <span v-if="booking.paid">
              <i class="bi bi-check2-all text-success"></i> Oui
            </span>
            <span v-else>
              <i class="bi bi-x-circle-fill text-danger"></i> Non
            </span>
          </td>
          <td>{{ booking.amountPaid }} DH</td>
          <td>
            <span v-if="booking.is_subscription">
              Oui
            </span>
            <span v-else>
              Non
            </span>
          </td>

          <td>
            <button @click="$router.push({ name: 'reservations-details', params: { reference: booking.reference } })"
              class="btn btn-info">
              Voir Détails
            </button>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("customer", {
      customer: "getCustomer",
    }),

    customerInfo() {
      return {
        "Nom": this.customer.lastName,
        "Prénom": this.customer.firstName,
        "Adresse": this.customer.address,
        "Ville": this.customer.city,
        "Code Postal": this.customer.codePostal,
        "CIN": this.customer.cin,
        "Téléphone": this.formatPhoneNumber(this.customer.phone),
        "E-mail": this.customer.email,
        "Remarque": this.customer.remark,
      };
    },
  },

  methods: {
    async referesh() {
      await this.$store.dispatch("customer/show", this.$route.params.reference);
    },

    createProfessionalReservation() {
      this.$router.push({
        name: "reservations-professionals-create",
        query: { fieldReference: this.customer.reference },
      });
    },

    createReservation() {
      this.$router.push({
        name: "reservations-create",
        query: { fieldReference: this.customer.reference },
      });
    },

    createSubscription() {
      this.$router.push({
        name: "subscriptions-create",
        params: { reference: this.customer.reference },
      });
    },

    createPayment() {
      this.$router.push({
        name: "payments-create",
        params: { reference: this.customer.reference },
      });
    },

    viewSubscriptionDetails(reference) {
      this.$router.push({
        name: "subscriptions-details",
        params: { reference },
      });
    },

    viewPaymentDetails(reference) {
      this.$router.push({
        name: "payments-details",
        params: { reference },
      });
    },

    toggleMatchStatus(matche) {
      matche.is_active = !matche.is_active;
    },

    editMatch(reference) {
      this.$router.push({
        name: "matches-edit",
        params: { reference },
      });
    },

    toggleBookingStatus(booking) {
      booking.is_active = !booking.is_active;
    },

    editBooking(reference) {
      this.$router.push({
        name: "bookings-edit",
        params: { reference },
      });
    },

    formatPhoneNumber(phone) {
      if (!phone) return ""; // Handle empty or undefined phone numbers
      return phone.replace(/(\d{2})(?=(\d{2})+(?!\d))/g, '$1 . ');
    },
  },

  beforeMount() {
    this.referesh();
  },
};
</script>

<style scoped>
/* Add any additional styling here if needed */
</style>
